<template>
    <div>
        <div class="bg-gray-900" style="height: 150px;">
            <div class="container py-2 block" :style="'margin-bottom: ' + bottomHeight + 'px;'">
                <div class="flex w-full justify-between sm:p-3 md:p-0 p-3">
                    <h5 class="text-white sm:text-xl md:text-2xl text-xl font-bold my-auto">Select Services</h5>
                    <div v-if="cmsLeft.Data && !cmsLeft.Data[0].Data.Description" class="my-auto text-white" @click="$router.push('/product?Oid=' + $route.query.item)">
                        <b-icon icon="close" size="is-medium"></b-icon>
                    </div>
                </div>
                <div class="flex relative" style="top: 30px;" v-show="cmsLeft.Data || cmsRight.Data">
                    <div :class="'md:w-' + cmsLeft.Column" class="sm:w-full w-full bg-white rounded-md shadow p-3 sm:m-0 md:mr-2 m-0">
                        <cms :data="cmsLeft.Data" />
                    </div>
                    <div :class="'md:w-' + cmsRight.Column" v-if="cmsRight" class="sm:w-full w-full sm:hidden md:block hidden bg-white rounded-md shadow ml-2 p-3" style="height: fit-content;">
                        <cms :data="cmsRight.Data" />
                    </div>
                </div>
            </div>
            <div id="bottom-fixed" class="fixed w-full bottom-0 left-0 bg-white p-2" v-if="config && config.StickyBook">
                <div class="container flex justify-between">
                    <span class="my-auto truncate">{{ config.Name }}</span>
                    <div class="flex">
                        <b-button type="is-dark" class="mr-2" v-if="config.StickyAddToCart">
                            <b-icon
                                icon="cart-outline"
                                size="is-small">
                            </b-icon>
                        </b-button>
                        <b-button type="is-dark" style="width: 125px;" @click="addToCart">Book Now</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        cms: () => import('@/components/cms')
    },
    data () {
        return {
            cmsLeft: [],
            cmsRight: [],
            config: {},
            back2: JSON.parse(localStorage.getItem('backHome2')) || false
        }
    },
    watch: {
        async '$route' (to, from) {
            this.init()
        }
    },
    methods: {
        async init () {
            var response = await this.$baseApi.get(this.$route.fullPath)
            const back = JSON.parse(localStorage.getItem('backHome'))
            const back2 = JSON.parse(localStorage.getItem('backHome2'))
            if (back2 && this.$route.path === '/item/payment') {
                this.$router.push('/product?Oid=' + JSON.parse(localStorage.getItem('Oid')))
                localStorage.removeItem('backHome')
                localStorage.removeItem('backHome2')
                localStorage.removeItem('backHome3')
            }
            if (back && this.$route.path === '/item/addon') {
                this.$router.push('/product?Oid=' + JSON.parse(localStorage.getItem('Oid')))
                localStorage.removeItem('backHome')
                localStorage.removeItem('backHome2')
                localStorage.removeItem('backHome3')
            }
            console.log('check response', response)
            this.cmsLeft = response.Left
            this.cmsRight = response.Right
            this.config = response.Config
            setTimeout(() => {
                var bottom = document.getElementById('bottom-fixed')
                this.$store.commit('service/SET_BOTTOM_HEIGHT', bottom ? bottom.clientHeight : 0)
            }, 200)
        },
        async addToCart () {
            localStorage.setItem('backHome', JSON.stringify(true))
            var data = this.getData('summary')
            if (data.Details[0].Date === '') return false
            var response = await this.$baseApi({
                method: 'POST',
                url: '/cart',
                data: data
            })
            this.$router.push({
                path: '/item/payment',
                query: {
                    Oid: response.Oid,
                    item: this.$route.query.item
                }
            })
        }
    },
    async created () {
        this.init()
    }
}
</script>
